// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/Jaap/Documents/development/cogo/website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bi-portals-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/bi-portals.js" /* webpackChunkName: "component---src-pages-bi-portals-js" */),
  "component---src-pages-compare-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-consultancies-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/consultancies.js" /* webpackChunkName: "component---src-pages-consultancies-js" */),
  "component---src-pages-customers-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-enterprise-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-index-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-pricing-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-js": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-api-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/api.md" /* webpackChunkName: "component---src-pages-api-md" */),
  "component---src-pages-company-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/company.md" /* webpackChunkName: "component---src-pages-company-md" */),
  "component---src-pages-design-agencies-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/design-agencies.md" /* webpackChunkName: "component---src-pages-design-agencies-md" */),
  "component---src-pages-faq-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/faq.md" /* webpackChunkName: "component---src-pages-faq-md" */),
  "component---src-pages-jobs-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/jobs.md" /* webpackChunkName: "component---src-pages-jobs-md" */),
  "component---src-pages-learning-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/learning.md" /* webpackChunkName: "component---src-pages-learning-md" */),
  "component---src-pages-partners-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/partners.md" /* webpackChunkName: "component---src-pages-partners-md" */),
  "component---src-pages-press-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/press.md" /* webpackChunkName: "component---src-pages-press-md" */),
  "component---src-pages-privacy-policy-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/privacy-policy.md" /* webpackChunkName: "component---src-pages-privacy-policy-md" */),
  "component---src-pages-security-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/security.md" /* webpackChunkName: "component---src-pages-security-md" */),
  "component---src-pages-terms-of-service-md": () => import("/Users/Jaap/Documents/development/cogo/website/src/pages/terms-of-service.md" /* webpackChunkName: "component---src-pages-terms-of-service-md" */)
}

exports.data = () => import("/Users/Jaap/Documents/development/cogo/website/.cache/data.json")

