module.exports = [{
      plugin: require('/Users/Jaap/Documents/development/cogo/website/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Jaap/Documents/development/cogo/website/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Jaap/Documents/development/cogo/website/node_modules/gatsby-plugin-segment/gatsby-browser'),
      options: {"plugins":[],"writeKey":"RKcmKL83eimTLFPAYwSLgpsrP2Mw1fdq"},
    }]
